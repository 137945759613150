@import "/node_modules/primeflex/primeflex.scss";

@layer biifor {

    /**
        ACCORDION
    */

    p-accordion-content {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    /**
        BLOCK UI
    */

    .p-blockui {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /**
        BOUTONS
    */

    .p-button {
        &.no-label {
            p-badge {
                @include styleclass('-ml-3');
            }
        }
    }

    .p-button-label {
        font-weight: 400;
        text-transform: uppercase;
    }

    /**
        BARRES D'OUTILS / DE MENU
    */

    .p-breadcrumb {

        @include styleclass('text-lg');

        li:last-child {
            .p-breadcrumb-item-label {
                @include styleclass('text-primary');
                @include styleclass('font-medium');
            }
        }
    }

    /**
        DRAG AND DROP
    */

    .dragged-over {
        @include styleclass('relative');
        @include styleclass('border-3');
        @include styleclass('border-dashed');
        @include styleclass('border-primary');
        @include styleclass('border-round-lg');
        @include styleclass('opacity-50');
        top: -.25rem;
        left: -.25rem;
        margin-bottom: -.5rem;
        margin-right: -.5rem;
        box-sizing: content-box;
    }

    /**
        FIELDSET
    */

    .p-fieldset-legend {
        @include styleclass('mb-3');
    }

    /**
        TABLES
    */

    p-table {

        // Taille de la table
        > :first-child {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        // Entête, selon si titre ou non
        .p-datatable-header {
            @include styleclass('border-round-top-xl p-3');
        }

        &.no-title {
            .p-datatable-table-container {
                @include styleclass('border-round-top-xl');
            }
        }

        .p-datatable-table-container {
            @include styleclass('border-round-bottom-xl');

            th {
                white-space: nowrap;
            }
        }

        .p-datatable-footer:not(:has(*)):empty {
            padding: 0;
        }

        .p-datatable-tfoot {
            position: sticky;
        }

        .p-paginator {
            @include styleclass('mt-4');

            .p-paginator-right-content,
            .p-paginator-left-content {
                flex-grow: 1;
            }
        }

        .first-row {
            border-top-width: 1px;
            border-top-color: var(--surface-400);
            padding: 0;
        }

        .last-row {
            border-bottom-width: 1px;
            border-bottom-color: var(--surface-400);
            padding: 0;
        }

        /**
            Sélection de colonne (utilisé pour les abonnements)
        */

        .p-datatable-thead>tr>th {
            &.selected-column {
                border: 2px solid var(--primary-color);
                border-bottom: 1px solid var(--gray-200);
                border-width: 2px 2px 1px 2px;
                border-top-left-radius: var(--border-radius-md);
                border-top-right-radius: var(--border-radius-md);
                padding: var(--border-radius-md);
                background-color: var(--blue-50)
            }
        }

        .p-datatable-tbody>tr>td {
            &.selected-column {
                border: 2px solid var(--primary-color);
                border-top: 1px solid var(--gray-200);
                border-bottom: 1px solid var(--gray-200);
                border-width: 0 2px 1px 2px;
                padding: var(--border-radius-md);
                background-color: var(--blue-50)
            }
        }

        .p-datatable-table-container {
            tr:last-child td.selected-column {
                border: 2px solid var(--primary-color);
                border-top: 1px solid var(--gray-200);
                border-width: 0 2px 2px 2px;
                border-bottom-left-radius: var(--border-radius-md);
                border-bottom-right-radius: var(--border-radius-md);
                padding: var(--border-radius-md);
                background-color: var(--blue-50)
            }
        }


    }

    .summary-error {
        color: var(--form-field-invalid-placeholder-color);
        padding: var(--datatable-footer-cell-padding);
    }

    /**
        FORMULAIRES
    */

    input::placeholder {
        color: var(--surface-400);
        font-style: italic;
        opacity: 1;
    }

    .p-autocomplete,
    .p-datepicker,
    .p-inputnumber,
    .p-inputtext,
    .p-multiselect,
    .p-password,
    .p-select:not(.p-paginator-rpp-dropdown),
    .p-textarea {
        width: 100%;
    }

    .p-textarea {
        resize: vertical;
    }

    .p-knob-text {
        font-size: 10px;
        font-weight: 600 !important;
    }

    .select-empty-message {
        width: calc(100% + 2.25rem);
        margin: -0.75rem -1.25rem;
        padding: 0.75rem 1.25rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: var(--surface-100);
        }
    }

    /**
        MESSAGES
    */

    .p-toast-detail {
        white-space: pre-line;
    }

    /**
        TAGS
    */

    .p-tag {
        letter-spacing: .5px;
    }

    /**
        TABS
    */

    .p-tabs {
        &.h-full {
            .p-tabpanels {
                height: calc(100% - 3.25rem);

                .p-tabpanel {
                    height: 100%;
                }
            }
        }
    }

    .p-tab {
        &.danger {
            color: var(--button-danger-background);
        }
    }

    /**
        UPLOAD
    */

    .p-fileupload {
        &.w-full {

            p-button,
            button {
                width: 100%;
            }
        }

        &.p-button-rounded {
            button {
                border-radius: var(--button-rounded-border-radius);
            }
        }
    }

}