@import "../extensions";

@layer biifor {

    /**
        TABLES
    */

    p-table {

        &:not(.flat) {
            .p-datatable-header,
            .p-datatable-table-container,
            .p-paginator {
                @include styleclass('shadow-1');
            }
        }
    }

}